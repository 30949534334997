import type { EmailCodeFactor } from "@clerk/types";
import {
  useSignIn,
  useSignUp,
  ClerkProvider,
  type ClerkProp,
} from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Github, Loader2 } from "lucide-react";
import { Button } from "@fefyi/ui/button";
import { $clerkStore } from "@clerk/astro/client";
import { useStore } from "@nanostores/react";
import type { WritableAtom } from "nanostores";
import { PUBLIC_CLERK_PUBLISHABLE_KEY } from "astro:env/client";

const Form = ({
  defaultVariant = "login",
}: {
  defaultVariant?: "login" | "signup";
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const { isLoaded, signIn, setActive } = useSignIn();
  const { isLoaded: isSignupLoaded, signUp } = useSignUp();
  const [isWaitingForCode, setIsWaitingForCode] = useState(false);
  const [usedEmail, setUsedEmail] = useState<string | null>(null);
  const [secondsAfterRequest, setSecondsAfterRequest] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [variant, setVariant] = useState<"login" | "signup">(defaultVariant);

  useEffect(() => {
    const error = new URL(window.location.href).searchParams.get("error");
    if (error) {
      const texts: Record<string, string> = {
        "no-session":
          "No account found. If you tried to login with Github, it might be you have never registered with this Github account. Please click 'create an account' and sign up with Github first.",
      } as const;

      if (texts[error]) {
        setError(texts[error]);
      }
    }
  }, []);

  useEffect(() => {
    const title = document.getElementById("form-title");
    if (!title) return;
    title.innerText = variant === "login" ? "Login" : "Sign up";
  }, [variant]);

  useEffect(() => {
    if (!isWaitingForCode || secondsAfterRequest > 60) return;

    const interval = setInterval(() => {
      setSecondsAfterRequest((s) => s + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isWaitingForCode, secondsAfterRequest]);

  const signInWithGithub = async () => {
    if (!signIn) return;
    await signIn.authenticateWithRedirect({
      strategy: "oauth_github",
      redirectUrl: "/logging-in",
      redirectUrlComplete: "/logging-in",
    });
  };

  const signUpWithGithub = async () => {
    if (!signUp) return;
    await signUp.authenticateWithRedirect({
      strategy: "oauth_github",
      redirectUrl: "/logging-in",
      redirectUrlComplete: "/logging-in",
    });
  };

  const requestSignInCode = async (email: string) => {
    setError(null);
    if (!signIn) return;
    setUsedEmail(email.toLowerCase());
    setSecondsAfterRequest(0);
    try {
      const si = await signIn.create({
        identifier: email.toLowerCase(),
      });

      if (!si.supportedFirstFactors) {
        setError(
          "Error when requesting sign in code, please try again (no factors defined).",
        );
        return;
      }

      const flow = si.supportedFirstFactors.find(
        (ff) =>
          ff.strategy === "email_code" &&
          ff.safeIdentifier.toLowerCase() === email.toLowerCase(),
      );

      if (!flow) {
        setError("Error when requesting sign in code, please try again.");
        return;
      }

      const { emailAddressId } = flow as EmailCodeFactor;
      await signIn
        .prepareFirstFactor({
          strategy: "email_code",
          emailAddressId,
        })
        .catch((err) => {
          throw new Error(err);
        });

      setIsWaitingForCode(true);
    } catch (err) {
      if (!signUp) {
        setError("Error when requesting sign in code, please try again.");
        return;
      }

      try {
        setIsSigningUp(true);
        await signUp.create({ emailAddress: email.toLowerCase() });
        await signUp.prepareVerification({ strategy: "email_code" });

        setIsWaitingForCode(true);
      } catch (err) {
        setIsSigningUp(false);
        setError("Error when requesting sign in code, please try again.");
      }
    }
  };

  const validateCode = async (code: string) => {
    setError(null);
    if (!signIn) return;

    try {
      if (isSigningUp) {
        const response = await signUp?.attemptVerification({
          strategy: "email_code",
          code,
        });

        if (response?.status === "complete" && response.createdSessionId) {
          await setActive({ session: response.createdSessionId });
          window.location.href = "/logging-in";
          return;
        }
      } else {
        const response = await signIn.attemptFirstFactor({
          strategy: "email_code",
          code,
        });

        if (response?.status === "complete" && response.createdSessionId) {
          await setActive({ session: response.createdSessionId });
          window.location.href = "/logging-in";
          return;
        }
      }

      throw new Error("Could not create a valid session");
    } catch (error) {
      setIsLoading(false);
      let errorMessage = JSON.stringify(error);
      if (typeof error === "object") {
        if ((error as any)?.errors?.[0]?.longMessage) {
          errorMessage = (error as any)?.errors?.[0]?.longMessage;
        }
      }

      setError(
        "Could not validate the code. Did you copy it correctly? " +
          errorMessage,
      );
    }
  };

  if (!isLoaded || !isSignupLoaded)
    return (
      <div className="in-react flex w-full justify-center py-6">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const formValues = new FormData(ev.target as HTMLFormElement);
        setIsLoading(true);

        if (!isWaitingForCode) {
          const email = formValues.get("email") as string;
          if (!isLoaded) return;
          await requestSignInCode(email);
          setIsLoading(false);
        } else {
          const code = formValues.get("code") as string;
          await validateCode(code);
        }
      }}
      className="relative mx-auto flex w-[90%] max-w-[450px] flex-col items-center"
    >
      {error && (
        <div className="mb-3 w-full rounded-xl border-2 border-red-800/40 bg-red-800/30 p-3 text-lg text-white">
          {error}
        </div>
      )}
      {isWaitingForCode ? (
        <>
          <p className="bg-white-opaque mb-2 rounded-lg p-3 text-center text-xl font-bold">
            Check your inbox for the code, and paste it below.
          </p>
          <input
            key="code"
            className="bg-black-opaque-dark mb-3 w-full rounded-xl border-none px-3 py-3 text-center text-lg text-white focus:ring-0 focus:outline-white md:px-5 md:py-2 md:text-2xl"
            type="text"
            pattern="[0-9]*"
            name="code"
            autoComplete="off"
            autoFocus
          />
          <div className="flex flex-col items-center">
            <Button intent="white" isLoading={isLoading} type="submit">
              Verify my code
            </Button>
            <Button
              intent="plain"
              type="button"
              disabled={!secondsAfterRequest || secondsAfterRequest < 60}
              className="bg-transparent!"
              onClick={() => {
                if (usedEmail) requestSignInCode(usedEmail);
              }}
            >
              Resend code{" "}
              {!!secondsAfterRequest && secondsAfterRequest < 60 && (
                <>({60 - secondsAfterRequest})</>
              )}
            </Button>
          </div>
        </>
      ) : (
        <>
          <input
            key="email"
            className="bg-black-opaque-dark mb-3 w-full rounded-xl border-none px-3 py-3 text-center text-lg text-white placeholder:text-slate-500 placeholder:italic focus:ring-0 focus:outline-white md:px-5 md:py-2 md:text-xl"
            type="email"
            name="email"
            required
            placeholder="janedoe@frontend.fyi"
          />
          <Button
            className="w-full justify-center"
            isLoading={isLoading}
            type="submit"
            intent="white"
          >
            {variant === "login" ? (
              <>Send me a login code</>
            ) : (
              <>Create my account</>
            )}
          </Button>
          <Button
            type="button"
            intent="plain"
            onClick={() => {
              setVariant((v) => (v === "login" ? "signup" : "login"));
            }}
          >
            {variant === "login" ? <>Create an account</> : <>Login instead</>}
          </Button>

          <div className="mt-6 flex w-full flex-col items-center justify-center text-center">
            <p className="container mb-5 w-full overflow-x-clip leading-0">
              <span className="before:w-container before:bg-white-opaque after:w-container after:bg-white-opaque relative inline-block px-3 font-mono font-bold before:absolute before:right-full before:h-px after:absolute after:left-full after:h-px">
                OR
              </span>
            </p>
            <Button
              type="button"
              onClick={
                variant === "login" ? signInWithGithub : signUpWithGithub
              }
              intent="white"
            >
              <Github className="mr-2" /> Sign{" "}
              {variant === "login" ? "in" : "up"} with Github
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export const LoginForm = ({
  defaultVariant = "login",
}: {
  defaultVariant?: "login" | "signup";
}) => {
  const Clerk = useStore($clerkStore! as unknown as WritableAtom);

  return (
    <ClerkProvider
      Clerk={Clerk as ClerkProp}
      publishableKey={PUBLIC_CLERK_PUBLISHABLE_KEY}
    >
      <Form defaultVariant={defaultVariant} />
    </ClerkProvider>
  );
};
